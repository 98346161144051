#banner_container {
    height: 80vh;
    padding: 0;
    margin: 5px;
}

body.banner {
color: rgb(67, 58, 112);
}

#banner_container div {
    background-position: center;
    background-size: cover;
    width: 33.3vw;
    overflow: hidden;
    padding: 0px;
    background-color: rgb(67, 58, 112) !important;

}

#banner_container div div {
    width: 20vw;
    margin-right: 0;
    margin-left: auto;
}

#banner_container div p {
    text-align: right;
}

#banner_container div h1 {
    color: white;
    padding: 5px;
}

#mathematics {
    background-image: url('./pages/media/math-chalkboard.jpg');
}

#mathematics div {
    margin-top: 3vh;
}

#philosophy {
    background-image: url('./pages/media/library-books.jpg');
}

#philosophy div {
    margin-top: 27vh;
}

#computer_science {
    background-image: url('./pages/media/coding-examples.jpg');
}

#computer_science div {
    margin-top: 52vh;
}

#banner_background {
    background-color: rgb(67, 58, 112);
    padding: 10px;
    height: 100vh;
}

#banner_background div {
    background-color:black;
    padding: 5px;
    border-radius: 10px;
}

#enter_menu_container.container {
    background-color: black;
    border-radius: 10px;
}

#enter_menu_container div.enter_menu {
    padding:0px;
    background-color: black;
    margin-left: auto;
    margin-right: auto;
}

#enter_menu_container div.enter_button {
    background-color: rgb(67, 58, 112);
    width: 17vw;
    text-align: left;
}

#enter_menu_container div.enter_menu h2 {
    margin: 10px;
}


#enter_menu_container div.enter_button:hover {
    background-color: rgb(129, 106, 255);
    cursor: pointer;
}